<template>
  <div class="container">
    <div class="a_file">
      <input type="file" ref="file" @change="handleFileUpload" style="display: none"/>
      <div class="container_title">
        <el-button type="primary" size="small" @click="$refs.file.click()">Выберите файл</el-button>
        <div class="a_file__title">{{ this.title }}</div>
      </div>
    </div>
    <hr style="margin: 5px">
    <label>Выберите загружаемые листы:
    </label>
    <br>
    <label>
      <input type="checkbox" id="card" ref="card" style="margin-left: 20px;" :disabled="localCheckboxes.block.card" :checked="localCheckboxes.value.card" v-on:change="handlerCheckboxes"/>
      Карточка проекта
    </label>
    <br>
    <label>
      <input type="checkbox" id="calendar_plan" ref="calendar_plan" style="margin-left: 20px;" :disabled="localCheckboxes.block.calendar_plan" :checked="localCheckboxes.value.calendar_plan" v-on:change="handlerCheckboxes"/>
      Фазы проекта
    </label>
    <br>
    <label>
      <input type="checkbox" id="investment_expenses_budget" ref="investment_expenses_budget" style="margin-left: 20px;" :checked="localCheckboxes.value.investment_expenses_budget" :disabled="localCheckboxes.block.investment_expenses_budget" v-on:change="handlerCheckboxes"/>
      План БИР
    </label>
    <br>
    <label>
      <input type="checkbox" id="investment_budget" ref="investment_budget" style="margin-left: 20px;" :disabled="localCheckboxes.block.investment_budget" :checked="localCheckboxes.value.investment_budget" v-on:change="handlerCheckboxes"/>
      План БДР
    </label>
    <br>
    <label>
      <input type="checkbox" id="quality" ref="quality" style="margin-left: 20px;" :disabled="localCheckboxes.block.quality" :checked="localCheckboxes.value.quality" v-on:change="handlerCheckboxes"/>
      Критерии качества
    </label>
    <br>
    <label>
      <input type="checkbox" id="risks" ref="risks" style="margin-left: 20px;" :disabled="localCheckboxes.block.risks" :checked="localCheckboxes.value.risks" v-on:change="handlerCheckboxes"/>
      Описание рисков
    </label>
    <br>
    <div style="text-align: right">
      <el-button size="small" type="primary" v-on:click="submitFile()">Загрузить</el-button>
    </div>
  </div>
</template>
<script>
export default {
  props: ['model', 'context', 'component', 'checkbox_settings'],
  data () {
    return {
      file: '',
      local: this.model,
      localContext: this.context,
      checkboxes: { card: false, risks: false, calendar_plan: false, quality: false, investment_expenses_budget: true, investment_budget: true },
      title: '',
      localCheckboxes: this.checkbox_settings
    }
  },
  watch: {
    local () {
      this.model = this.local
      this.context = this.localContext
      this.checkbox_settings = this.localCheckboxes
    },
    model () {
      this.local = this.model
      this.localContext = this.context
      this.localCheckboxes = this.checkbox_settings
    }
  },
  methods: {
    submitFile () {
      let me = this
      this.card = this.context.getCard()
      let context = this.context
      let formData = new FormData()
      let file = this.file
      formData.append('file', file)
      formData.append('scenarioId', this.local)
      this.card.setLoading(true)
      this.loader = this.context.$loading()
      this.$http.post(`${this.$config.api}/registryservice/plugins/execute/CompareInvestmentRequestsBeforeImportCommand`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          hideNotification: true
        }
      ).then((response) => {
        if (!response.data.is_equal) {
          this.context.$msgbox.close()
          this.loader.close()
          this.context.$confirm(
            'Проект в который производится импорт и проект в загруженном файле отличаются. Вы действительно хотите продолжить?',
            'Внимание',
            {
              confirmButtonText: 'Да',
              cancelButtonText: 'Нет',
              type: 'warning'
            }
          ).then((r1, r2) => {
            if (r1 === 'confirm') {
              this.sendImport(file)
            } else {
              this.card.setLoading(false)
            }
          }).catch((reason) => {
            this.card.setLoading(false)
          })
        } else {
          this.sendImport(file)
        }
      }).catch((r) => {
        this.context.$msgbox.close()
        this.loader.close()
        this.card.setLoading(false)
      })
    },
    handleFileUpload () {
      this.file = this.$refs.file.files[0]
      this.title = this.file.name
    },
    handlerCheckboxes () {
      let checkboxes = {}
      checkboxes.risks = this.$refs.risks.checked
      checkboxes.calendar_plan = this.$refs.calendar_plan.checked
      checkboxes.quality = this.$refs.quality.checked
      checkboxes.investment_expenses_budget = true
      checkboxes.investment_budget = true
      checkboxes.card = this.$refs.card.checked
      this.checkboxes = checkboxes
    },
    sendImport (file) {
      let uploadFormData = new FormData()
      uploadFormData.append('file', file)
      this.$http.post(`${this.$config.api}/registryservice/files`,
        uploadFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          hideNotification: true
        }
      ).then((response) => {
        let fileId = response.data.id
        this.$http.post(`${this.$config.api}/registryservice/plugins/execute/ImportAsyncCommand`,
          {
            'scenarioId': this.model,
            'attachmentId': fileId,
            'importSheets': this.checkboxes,
            'async': 'true'
          },
          {
            headers: {
              'Content-Type': 'application/json'
            },
            hideNotification: true
          }
        ).then((response) => {
          this.context.$msgbox.close()
          this.loader.close()
          this.context.$msgbox({
            type: 'info',
            message: 'Загрузка шаблона поставлена в очередь. По завершении получите уведомление'
          })
          this.card.setLoading(false)
        })
      })
    }
  }
}
</script>
<style>
  .a_file {
    height: 100%;
    flex-direction: column;
    /* display: inline-block; */
  }
  .a_file__title {
    width: 100%;
    line-height: 28px;
    letter-spacing: 0.4px;
    color: #1D242B;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .a_file .container_title {
    display: inline-block;
    background: white;
    display: flex;
    align-items: baseline;
    flex-basis: 100%;
    flex-direction: column;
  }
  .a_file .view {
    background: #6E7B87;
    box-shadow: 0 0 8px 0 rgba(232,237,250,.6), 0 2px 4px 0 rgba(232,237,250,.5);
    /* width: 75%; */
    overflow: auto;
    border-radius: 8px;
    display: flex;
    flex: 1 1 auto;
    position: relative;
  }
  .a_file .container_view {
    display: flex;
    flex: auto;
    max-height: calc(100% - 52px);
  }
  .a_file .container_list {
    box-sizing: border-box;
    background: white;
    padding: 10px;
    flex: none;
    overflow-y: auto;
  }
  .a_file .list_items {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
  }
  .a_file .active_file {
    background: #EEF0F1;
  }
  .a_file .active_file .remove_file {
    display: inline-block;
  }
  .a_file .file_listing {
    cursor: pointer;
    white-space: nowrap;
    margin-bottom: 3px;
    padding: 5px 15px;
    padding-left: 5px;
    flex: none;
    /* width: 84%; */
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .a_file .file_listing:hover{
    background: #EEF0F1;
  }
  .a_file .remove_file {
    display: none;
    cursor: pointer;
    z-index: 2;
    position: absolute;
    background: #EEF0F1;
    padding-left: 5px;
    width: 18px;
    right: 8px;
  }
  .a_file .file_listing:hover .remove_file {
    display: inline-block;
  }
  .a_file .preview_image {
    /*width: 100%;*/
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    margin: auto;
  }
  .a_file .preview_pdf {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    margin: auto;
  }
  .a_file .preview_doc {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    margin: auto;
  }
  .a_file .preview_text {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .a_file .preview_text span {
    color: white;
    font-size: 18px;
  }
  .a_file .download_icon {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 20px;
    bottom: 20px;
    background: rgba(37, 47, 55, 0.6);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .a_file .download_icon i {
    font-size: 28px;
    color: white;
  }
</style>
