import BasePlugin from '../BasePlugin'
import ImportInvestmentRequestAsyncCmp from './ImportInvestmentRequestAsyncCmp'

export default class ImportInvestmentRequestAsync extends BasePlugin {
  async execute () {
    let scenarioId = this.context.getModel().id
    const h = this.context.$createElement
    this.context.$http({
      method: 'get',
      url: `${this.context.$config.api}/registryservice/plugins/execute/CheckBlockCheckboxesBeforeAsyncImportQuery?scenarioId=${scenarioId}`,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      hideNotification: true
    }).then((response) => {
      this.context.$msgbox({
        title: 'Загрузка сценария',
        message: h('p', { key: Math.random() }, [
          h(ImportInvestmentRequestAsyncCmp, { props: { model: scenarioId, context: this.context, checkbox_settings: response.data } })
        ]),
        showCancelButton: false,
        showConfirmButton: false
      })
    })
  }
}
